import { RouteLocationRaw } from "vue-router";
import { toast } from "../helpers/externalToast";
import { parseJWT } from "../helpers/jwt";
import { i18n } from "../i18n";
import { MiddlewareContext, MiddlewareReturn } from "./middlewares";

export function requireUrlToken(
  redirection: RouteLocationRaw = { name: "Login" },
) {
  return async ({ to }: MiddlewareContext): Promise<MiddlewareReturn> => {
    const { t } = i18n.global;
    if (to.query.token === undefined) {
      console.error("No token provided");
      toast.add({
        severity: "error",
        summary: t("login.token_not_found"),
        detail: t("login.token_not_found_content"),
        life: 10000,
      });
      return {
        type: "redirect",
        redirect: redirection,
      };
    }

    const token = to.query.token as string;
    const parsedToken = token !== null ? parseJWT(token) : null;
    if (parsedToken === null) {
      console.error("Invalid token");
      toast.add({
        severity: "error",
        summary: t("login.token_invalid"),
        detail: t("login.token_invalid_content"),
        life: 10000,
      });
      return {
        type: "redirect",
        redirect: redirection,
      };
    }

    if (parsedToken.exp < Date.now() / 1000) {
      console.error("Token expired");
      toast.add({
        severity: "error",
        summary: t("login.token_expired"),
        detail: t("login.token_expired_content"),
        life: 10000,
      });
      return {
        type: "redirect",
        redirect: redirection,
      };
    }

    return {
      type: "next",
    };
  };
}
