import { createSentryPiniaPlugin } from "@sentry/vue";
import "@si/graphics/dist/graphics.css";
import { createPinia } from "pinia";
import PrimeVue from "primevue/config";
import Ripple from "primevue/ripple";
import ToastService from "primevue/toastservice";
import { createApp } from "vue";
import App from "./App.vue";
import {
  i18n,
  loadLocaleMessages,
  setI18nLanguage,
  SUPPORT_LOCALES,
} from "./i18n";
import { OkidiaPreset } from "./primevuePresets";
import { createMainRouter } from "./router";
import { mainRoutes } from "./router/routes";
import { createSentry } from "./sentry";
import { useLocalesStore } from "./stores/locales";
import "./style.css";

loadLocaleMessages(i18n.global, i18n.global.locale.value)
  .then(() => {
    const router = createMainRouter(mainRoutes);
    router.beforeEach(async (to, from, next) => {
      const localesStore = useLocalesStore();
      let locale = Array.isArray(to.params.locale)
        ? to.params.locale[0]
        : to.params.locale;
      if (
        locale === undefined ||
        locale === null ||
        locale === "" ||
        !SUPPORT_LOCALES.includes(locale)
      ) {
        locale = localesStore.locale;
      }
      if (!i18n.global.availableLocales.includes(locale)) {
        await loadLocaleMessages(i18n.global, locale);
      }
      await localesStore.setLocale(locale);
      setI18nLanguage(i18n.global, locale);

      // manage displaying translate keys (?tkey)
      if ("tkey" in to.query) {
        i18n.global.setPostTranslationHandler(
          <T = string>(translated: T, key: string) => {
            let result = "[" + key + "]";
            if (to.query.tkey !== "only") {
              result = translated + " " + result;
            }
            return result;
          },
        );
      }

      next();
    });

    const md5Worker = new Worker(new URL("./md5Worker.ts", import.meta.url), {
      type: "module",
    });
    window.md5Worker = md5Worker;

    const multiavatarWorker = new Worker(
      new URL("./multiavatarWorker.ts", import.meta.url),
      {
        type: "module",
      },
    );

    window.multiavatarWorker = multiavatarWorker;

    const pinia = createPinia();
    pinia.use(createSentryPiniaPlugin());

    const app = createApp(App)
      .use(pinia)
      .use(router)
      .use(i18n)
      .use(PrimeVue, {
        theme: {
          preset: OkidiaPreset,
        },
        ripple: true,
      })
      .use(ToastService)
      .directive("ripple", Ripple);
    createSentry(app, router);
    const meta = document.createElement("meta");
    meta.name = "naive-ui-style";
    document.head.appendChild(meta);
    app.mount("#app");
  })
  .catch((e) => {
    console.error(e);
  });
