<template>
  <component
    :is="props.to ? RouterLink : 'div'"
    :to="props.to"
    class="inline-block"
  >
    <button
      :type="props.type"
      :class="buttonClasses"
      :disabled="props.disabled"
      @click="$emit('click')"
    >
      <template v-if="icon">
        <OIcon :icon="icon" />
      </template>
      <slot>{{ label }}</slot>
      <template v-if="iconRight">
        <OIcon :icon="iconRight" />
      </template>
    </button>
  </component>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { RouteLocationRaw, RouterLink } from "vue-router";
import OIcon from "./OIcon.vue";

const props = withDefaults(
  defineProps<{
    /** The text of the button */
    label?: string;
    /** The type of the button (useful to submit current form) */
    type?: "button" | "submit";
    /** The color of the button */
    color?:
      | "primary"
      | "secondary"
      | "tertiary"
      | "success"
      | "warning"
      | "danger";
    /** Allow to use outlined version of the button (still depending on the color) */
    outline?: boolean;
    /** Left icon of the button */
    icon?: string;
    /** Right icon of the button */
    iconRight?: string;
    /** Size of the button */
    size?: "small" | "medium";
    /** Disabled label for responsive */
    disabled?: boolean;
    /** The internal link of the button (RouterLink) */
    to?: RouteLocationRaw;
  }>(),
  {
    label: "",
    type: "button",
    color: "primary",
    outline: false,
    icon: "",
    iconRight: "",
    size: "medium",
    disabled: false,
    to: undefined,
  },
);
defineEmits(["click"]);

// classes by size
const classesBySize: Record<string, string> = {
  small: "py-1.5 px-2 text-sm",
  medium: "py-2 px-4 text-sm",
};

const buttonClasses = computed(() => {
  // Classes by default
  const classes = ["flex gap-2 rounded-lg text-center items-center"];

  // add size
  classes.push(classesBySize[props.size]);

  // disabled
  if (props.disabled) {
    classes.push("cursor-not-allowed");
    if (props.outline) {
      classes.push("grayscale-[70%]");
    } else {
      classes.push("contrast-[60%]");
    }
  } else {
    classes.push("cursor-pointer");
  }

  // outline button
  if (props.outline) {
    classes.push(
      `text-${props.color}-accent bg-${props.color}-contrast border border-${props.color}-accent hover:bg-${props.color}-accent hover:text-${props.color}-contrast`,
    );
  } else {
    // non outline button
    classes.push(
      `shadow-md text-${props.color}-contrast bg-${props.color} hover:bg-${props.color}-accent`,
    );
  }

  // Specific case for white background => add border
  if (props.color === "tertiary") {
    classes.push(`border border-gray-300`);
  }

  return classes;
});
</script>
